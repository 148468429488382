<template>

  <section id="gallery">

    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>
  
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          <i class="fa-solid fa-images color-secondary"></i>
          {{ subtitle }}
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          to="/galeria/add" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Crear galería
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-7 input-search">
            <input
                type="search" 
                class="form-control input-custom"
                placeholder="Buscar album" 
                v-model.trim="search_text"
                @keypre.delete="eraseText()"/>
                <span class="input-search-icon">
                  <font-awesome-icon icon="search"/>
                </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon 
              icon="filter" 
              class="color-secondary pe-2"
            />
            Filtrar
          </div>
          <div class="col-12 col-lg-9 col-xl-8">
            <div class="row" v-click-outside="handleCalendar">
              <div class="col-6">
                <div 
                  class="form-control input-calendar" 
                  @click="openCalendar('start-date')">
                  <span>{{ dateFormatymd(init_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="clearDates()"
                    v-show="init_date != 'Inicio'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div 
                  class="form-control input-calendar disabled" 
                  v-if="init_date == 'Inicio'">
                  <span>{{ end_date }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
                <div
                  class="form-control input-calendar"
                  v-if="init_date != 'Inicio'"
                  @click="openCalendar('end-date')">
                  <span>{{ dateFormatymd(end_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="clearDates()"
                    v-show="end_date != 'Término'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-12 position-relative">
                <div 
                  class="input-calendar-dropdown" 
                  :class="calendar_side" 
                  v-if="show_calendar">
                  <v-calendar
                    color="blue"
                    :max-date="max_date"
                    :min-date="min_date"
                    :attributes="attributes"
                    @dayclick="onDayClick"
                  />
                  <div 
                    class="border-arrow" 
                    :class="calendar_side">
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Spinner v-if="show_spinner"/>

    <div 
      v-show="galleries_list.length > 0 && no_results == ''"
      class="row">
      <div   
        class="col-12 col-md-6 col-lg-4"
        v-for="(n, i) in galleries_list"
        :key="i"
      >
      <article class="card card-item card-shadow border-round-10 border-0 mb-3">
          <figure class="card-item-figure">
              <div class="card-item-config">
                <a 
                  href="javascript:" 
                  @click="openWatchAlbum(n)"
                  data-bs-toggle="modal" 
                  data-bs-target="#news-detail"
                  class="card-item-config-btn"
                  content="Ver álbum" 
                  v-tippy="{ arrow: true }"
                >
                  <font-awesome-icon icon="eye"/>
                </a>
                <a 
                  href="javascript:" 
                  @click="downloadAlbum(n); nombreGaleria = n.titulo_galeria;"
                  class="card-item-config-btn"
                  content="Descargar álbum"
                  v-tippy="{ arrow: true }"
                >
                  <font-awesome-icon icon="download"/>
                </a>
                <a 
                  href="javascript:" 
                  @click="openEditAlbum(n.id_galeria)"
                  class="card-item-config-btn"
                  content="Editar álbum" 
                  v-tippy="{ arrow: true }"
                >
                  <font-awesome-icon icon="pen"/>
                </a>
                <a 
                  href="javascript:" 
                  @click="openDeleteAlbum(n.id_galeria)"
                  class="card-item-config-btn"
                  content="Eliminar álbum"
                  v-tippy="{ arrow: true }" 
                >
                  <font-awesome-icon icon="trash-alt"/>
                </a>
              </div>
              <span class="card-item-date">
                {{ handleAlbumDate(n.fecha_galeria) }}
              </span>
              <img 
                :src="n.imagenes[0].url_imagen_original"
                class="card-item-figure-img"
              >
          </figure>
          <div class="card-body card-item-excerpt">
            <h5 class="card-item-title-1 font-main color-gray">
              <font-awesome-icon icon="images" class="color-main pe-1"/>
              {{ handleAlbumTitle(n.titulo_galeria) }}
            </h5>
            <div class="card-item-data">
              <span>
                <font-awesome-icon 
                  icon="image" 
                  class="color-secondary pe-1"
                />
                {{ n.imagenes.length }}
                {{ n.imagenes.length > 1 ? "Fotos" : "Foto" }}
              </span>
              <span>
                <i 
                v-if="handleLikeIconClass(n.total_likes_admin)"
                class="fa-solid fa-heart color-rose-shadow">
              </i>
              <i 
                v-else
                class="fa-regular fa-heart color-main">
              </i>
                {{ n.total_likes_admin == null ? 0 : n.total_likes_admin }}
                Me gusta
              </span>
            </div>
          </div>
        </article>

      </div>
      <div class="users-list__pagination" v-if="pagination.page_count > 0">
          <paginate
            v-model="pagination.actual_page"
            :page-count="paginas_galeria.length"
            :page-range="pagination.page_range"
            :click-handler="pagination.click_handler"
            :prev-text="pagination.prev_text"
            :next-text="pagination.next_text"
            :container-class="pagination.container_class"
            :page-class="pagination.page_class"
          />
        </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="alert alert-info text-center" v-show="no_results !== ''">
          <font-awesome-icon icon="circle-info"/>
          {{ no_results }}
        </div>
      </div>
    </div>

      <!-- Modal para ver fotos -->
      <div class="gallery-modal" v-if="open_modal_photos">
        <div class="gallery-modal-watch">
          <div class="close">
            <button @click="closeModal">
              <font-awesome-icon icon="times"></font-awesome-icon>
            </button>
          </div>
          <div class="album-name">
            <div class="name">{{ album_selected.titulo_galeria }}</div>
          </div>
          <div class="album-photos">
            <div class="slideshow-container">
              <div
                ref="mySlides"
                class="mySlides fade_slide"
                v-for="(img, i) in album_selected.imagenes"
                :key="i"
              >
                <img :src="img.url_imagen_original" class="photo" />
                <!-- opciones me gusta/descargar foto -->
                <div class="photo-options">
                  <div class="like-photo" v-if="handlelikeBtn(img, 'like')">
                    <span
                      content="Me gusta esta foto"
                      v-tippy="{ arrow: true }"
                      @click="likeImage(img.id_imagen, album_selected.id_galeria)"
                    >
                      <heart-border />
                    </span>
                  </div>
                  <div class="unlike-photo" v-if="handlelikeBtn(img, 'unlike')">
                    <span
                      content="No me gusta esta foto"
                      v-tippy="{ arrow: true }"
                      @click="
                        unlikeImage(img.id_imagen, album_selected.id_galeria)
                      "
                    >
                      <heart-fill />
                    </span>
                  </div>
                  <div class="download-photo show">
                    <span
                      content="Descargar foto"
                      v-tippy="{ arrow: true }"
                      @click="
                        downloadImage(img.url_imagen_original, album_selected)
                      "
                    >
                      <font-awesome-icon icon="download"></font-awesome-icon>
                    </span>
                  </div>
                  <div class="full-photo show">
                    <span
                      content="Ver foto completa"
                      v-tippy="{ arrow: true }"
                      @click="
                        (open_full_img = true),
                          (url_selected = img.url_imagen_original)
                      "
                    >
                      <font-awesome-icon icon="expand"></font-awesome-icon>
                    </span>
                  </div>
                </div>
                <!-- vista de likes -->
                <div class="img-likes">
                  <span class="like-icon">
                    <heart-fill v-if="img.likes.length > 0" />
                    <heart-border v-if="img.likes.length == 0" />
                  </span>
                  <p>{{ img.likes && img.likes.length }}</p>
                </div>
              </div>
            </div>
            <div class="controls">
              <!-- Next and previous buttons -->
              <a class="prev" @click="plusSlides(-1)">&#10094;</a>
              <div class="dots-container">
                <span
                  class="dot"
                  @click="currentSlide(i + 1)"
                  v-for="(dot, i) in album_selected.imagenes"
                  :key="i"
                ></span>
              </div>
              <a class="next" @click="plusSlides(1)">&#10095;</a>
            </div>
          </div>
        </div>
      </div>
      <div class="full-img-modal" v-if="open_full_img">
        <div
          class="full-img-modal__close"
          @click="(open_full_img = false), (url_selected = '')"
        >
          <font-awesome-icon icon="times"></font-awesome-icon>
        </div>
        <div class="full-img-modal__container">
          <img class="full-img-modal__img" :src="url_selected" alt="" />
        </div>
      </div>
      <!-- Pregunta para eliminar album -->
      <Question
        v-if="open_question_modal"
        :msg="question_modal_msg"
        :hideCancel="false"
        @cancel="cancelQuestion"
        @accept="deleteAlbum"
      />
      <!--  Modal que muestra el estatus de la accion (exito o error) -->
      <Status
        v-if="open_modal_status"
        :msg="modal_status_msg"
        :status="modal_status"
        @close="closeStatus"
      />
      <!-- Spinner -->
      <Spinner v-if="show_spinner"/>
  </section>

</template>

<script>
import Status from "../Modales/Status.vue";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import Spinner from "../Spinner.vue";
import { mapState, mapActions } from "vuex";
//import { LIKE_IMAGE, UNLIKE_IMAGEN } from "@/apollo/mutations";
import {
  //GET_YEARS_GALERIA,
  GET_MONTHS_GALERIA,
  //GET_GALERIES,
} from "@/apollo/queries";
import SectionTabs from "../Section/SectionTabs.vue";
import Question from "../Modales/Question.vue";
import HeartBorder from "../SVG/HeartBorder.vue";
import HeartFill from "../SVG/Heart_Fill.vue";
import { downloadZip } from "client-zip";

export default {
  components: {
    Status,
    Spinner,
    SectionTabs,
    Question,
    HeartBorder,
    HeartFill,
  },

  data() {
    return {
      tab_component: [{
        name: "Galería",
        route: "gallery-list",
      }, {
        name: "Agregar",
        route: "gallery-add",
      }],
      subtitle:
        "Conoce todos los álbumes de fotos que están publicados en LinkiWork. Podrás descargarlos, editarlos o eliminarlos si lo requieres.",
      open_modal_photos: false,
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: false,
      show_spinner: false,
      galeria_selected_id: "",
      album_selected: null,
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      month_list: [],
      month_selected: { name: "Mes", id: "" },
      year_list: [],
      year_selected: "Año",
      open_select_list: "",
      galleries_list: [],
      search_text: "",
      no_results: "",
      id_usuario: this.$ls.get("user").id_usuario,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      order_list: [{
        amount: 1,
        size: 768,
      }, {
        amount: 2,
        size: 1280,
      }, {
        amount: 3,
        size: 1366,
      }, {
        amount: 4,
        size: 1440,
      }, {
        amount: 5,
        size: 1920,
        width: "",
      }],
      selected_order: {
        amount: 0,
        size: 0,
      },
      width_items: 200,
      open_question_modal: false,
      question_modal_msg: "",
      slide_idx: 1,
      date: new Date(),
      show_back_btn: false,
      open_full_img: false,
      url_selected: "",
      show_like: false,
      arrayDef: [],
      counterAlbum: 0,
      totalLengthAlbum: 0,
      show_calendar: false,
      calendar_side: "",
      init_date: "Inicio",
      end_date: "Término",
      max_date: "",
      min_date: "",
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      pagina_actual: "1",
      pagination: {
        actual_page: 1,
        page_count: 1,
        page_range: 3,
        click_handler: this.paginacion,
        prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class:"pagination-item"
      },
    };
  },

  watch: {
    sidebar_open() {
      this.getScreenWidth();
    },

    // Búsqueda de álbum por nombre
    search_text() {
      if (this.search_text.length >= 3) {
        this.getPaginadasGaleriaLista();
        // const search = this.search_text.toLowerCase();
        // const search_result = this.album_list.filter((album) =>
        //   album.titulo_galeria.toLowerCase().includes(search)
        // );
        // if (search_result.length > 0) {
        //   this.galleries_list = [];
        //   search_result.forEach((item) => {
        //     this.galleries_list.push(item);
        //     this.no_results = "";
        //   });
        // } else {
        //   this.galleries_list = [];
        //   this.no_results = this.$noResults;
        // }
      }else if(this.search_text.length == 0){
        this.getPaginadasGaleriaLista();
      }
    },

    album_list() {
      if (this.album_list && this.album_list.length > 0) {
        this.galleries_list = this.album_list;
        this.month_selected = { name: "Mes", id: "" };
        this.year_selected = "Año";
      }
    }
  },

  computed: {
    ...mapState("galleryModule", ["album_list", "sidebar_open", "paginas_galeria"]),

    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    }
  },

  created() {
    const current_year = this.date.getFullYear();
    // try {
    //   window.addEventListener("resize", this.getScreenWidth);
    //   this.$refs.list.clientWidth.addEventListener(
    //     "resize",
    //     this.getScreenWidth
    //   );
    // } catch (err) {
    //   if (err) {
    //     // console.log("");
    //   }
    // }
    this.getPaginadasGaleriaLista();
    this.getYears();
    this.getMonths(current_year.toString());
  },

  async mounted() {
    
    //await this.getGalleries();
    //await this.getPaginadasGaleriaLista();
    // this.getYears();
    // this.getMonths(current_year.toString());
  },

  destroyed() {
    try {
      window.removeEventListener("resize", this.getScreenWidth);
      this.$refs.list.clientWidth.removeEventListener(
        "resize",
        this.getScreenWidth
      );
    } catch (err) {
      if (err) {
        console.log("");
      }
    }
  },

  methods: {
    ...mapActions("galleryModule", [
      "getGalleriesList",
      "deleteGalleryAction",
      "editGalleryAction",
      "getPaginasGaleria",
      "getGaleriaList"
    ]),
    //Paginador galerías
    paginacion(){
      console.log("estoy en la acción ");
      let total = this.paginas_galeria.length;
      if(this.pagination.actual_page <= total){
        //this.pagina_actual = total;
        console.log("para llamar al metodo de pagina");
        this.pagina_actual = this.pagination.actual_page;
        this.getPaginadasGaleriaLista();
      }
    },
    // Metodo que pagina y puebla la lista de galería para mostraren front
    async getPaginadasGaleriaLista(){
      this.show_spinner = true;
      let data_ = {
        id_empresa: this.id_empresa,
        fecha_inicio: this.init_date == "Inicio" ? "" : this.init_date,
        fecha_final: this.end_date == "Término" ? "" : this.end_date,
        nombre: this.search_text,
        limite: "10"
      };
      await this.getPaginasGaleria(data_).then(res=>{
        res.forEach((data) => {
          if (data.pagina == this.pagina_actual) 
          {
            //console.log("Estoy para llamar a getListaAlertaPush")
            let variables = {
              id_empresa: this.id_empresa,
              id_usuario: "0",
              id_galeria: data.galerias
            }
            this.getGaleriaList(variables);
            this.galleries_list = this.album_list;
          }
          else{
            console.log("pagina_actual: ",this.pagina_actual);
          }
        });
      });
      this.show_spinner = false;
    },
    // Metodo DEPRECADO para lista paginada
    // async getGalleries() {
    //   this.show_spinner = true;
    //   if (this.album_list.length == 0) {
    //     const payload = {
    //       id_empresa: this.id_empresa,
    //       fecha_inicio: this.init_date == "Inicio" ? "" : this.init_date,
    //       fecha_final: this.end_date == "Término" ? "" : this.end_date,
    //       nombre: "",
    //     };
    //     const res = await this.getGalleriesList(payload);
    //     if (!res) {
    //       this.show_spinner = false;
    //       this.toastGetGalleriesError();
    //       return;
    //     }
    //   }
    //   this.galleries_list = this.album_list;
    //   this.show_spinner = false;
    // },

    openWatchAlbum(album) {
      this.open_modal_photos = true;
      this.album_selected = album;

      setTimeout(() => {
        this.currentSlide(1);
      }, 500);
    },
    // Cerrar modal y limpiar variables
    closeModal() {
      this.open_modal_photos = false;
      this.album_selected = null;
    },

    async deleteAlbum() {
      this.open_question_modal = false;
      this.show_spinner = true;

      const payload = {
        id_empresa: this.id_empresa,
        id_galeria: this.galeria_selected_id,
        id_usuario: this.id_usuario,
      }
      const res = await this.deleteGalleryAction(payload);
      if (res) {
        this.show_spinner = false;
        this.toastDeleteGallerySuccess();
        this.galleries_list = this.album_list;
      } else {
        this.show_spinner = false;
        this.toastDeleteGalleryError();
      }
    },

    // Funcion para abrir modal de eliminar album
    openDeleteAlbum(id) {
      this.open_question_modal = true;
      this.question_modal_msg = "¿Realmente deseas eliminar este álbum?";
      this.galeria_selected_id = id;
    },
    // Funcion para editar un album
    openEditAlbum(album) {
      this.$router
        .push({
          name: "gallery-edit",
          params: { id: album },
        })
        .catch(() => {
          return;
        });
    },
    // Funcion para cerrar modal que muestra el estatus de la accion (exito o error)
    closeStatus() {
      this.open_modal_status = false;
    },
    // Funcion para mostrar la fecha del album formateada
    handleAlbumDate(date) {
      var p = date.split(/\D/g);
      return [p[2], p[1], p[0]].join("/");
    },
    // abrir selects de mes o año
    openSelect(item) {
      if (item == this.open_select_list) {
        this.open_select_list = "";
      } else {
        this.open_select_list = item;
      }
    },
    // seleccionar año (DEPRECADO)
    selectYear(year) {
      this.year_selected = year;
      this.show_spinner = true;
      setTimeout(() => {
        this.open_select_list = "";
        this.searchAlbumByDate();
      }, 400);
    },
    // Seleccionar mes (DEPRECADO)
    selectMonth(month) {
      for (let i = 0; i < this.month_list.length; i++) {
        if (month == this.month_list[i].name) {
          this.month_selected = this.month_list[i];
        }
      }
      setTimeout(() => {
        this.open_select_list = "";
        if (this.year_selected != "Año") {
          this.searchAlbumByDate();
        }
      }, 400);
    },

    // Buscar album por fecha
    async searchAlbumByDate() {
      console.log("searchAlbumByDate: por fecha inicio: ",this.init_date, " fecha de término: ",this.end_date);
      await this.getPaginadasGaleriaLista();
    },
    // Obtener años donde hay galerias (Metodo deprecado)
    getYears() {
      console.log("metodo de getYears, se depreca");
      // this.$apollo
      //   .query({
      //     query: GET_YEARS_GALERIA,
      //     variables: {
      //       id_empresa: this.id_empresa,
      //       id_usuario: this.id_usuario,
      //     },
      //   })
      //   .then((res) => {
      //     var resp = res.data.yearGaleria;
      //     // console.log("getYears", resp);
      //     resp.forEach((year) => {
      //       this.year_list.push(year.year);
      //     });
      //   })
      //   .catch((err) => {
      //     console.log("err", err);
      //   });
    },
    // obtener meses donde hay galerias
    getMonths(year) {
      this.$apollo
        .query({
          query: GET_MONTHS_GALERIA,
          variables: {
            id_empresa: this.id_empresa,
            id_usuario: this.id_usuario,
            year: year,
          },
        })
        .then((res) => {
          var resp = res.data.monthGaleria;
          // console.log("getMonths", resp);
          resp.forEach((mes) => {
            var month = {
              name: this.months[Number(mes.month - 1)],
              id: mes.month,
            };
            this.month_list.push(month);
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // Volver despues de la busqueda
    getBack() {
      this.galleries_list = this.album_list;
      this.no_results = "";
      this.init_date = "Inicio";
      this.end_date = "Término";
      this.search_text = "";
      this.show_back_btn = false;
    },
    // funcion que arregla el estilo/ancho de las galerias segun el ancho de pantalla
    getScreenWidth() {
      try {
        var size_ = window.innerWidth;
        // console.log(size_);
        var container_magin = 30;

        var container_width = this.$refs.list.clientWidth;
        // console.log(container_width);
        for (let i = 0; i < this.order_list.length; i++) {
          var before = 0;
          //console.log(i)
          if (i < 1) {
            if (size_ < this.order_list[i].size) {
              this.selected_order = this.order_list[i];
              break;
            }
          } else {
            before = i - 1;
          }
          if (
            size_ > this.order_list[before].size &&
            size_ <= this.order_list[i].size
          ) {
            this.selected_order = this.order_list[i];
            break;
          }
          if (i == this.order_list.length - 1) {
            if (size_ > this.order_list[i].size) {
              this.selected_order = this.order_list[i];
              break;
            }
          }
        }
        var final_container_width =
          container_width - container_magin * (this.selected_order.amount - 1);
        var item_width = final_container_width / this.selected_order.amount;
        this.width_items = item_width - 8;
      } catch (err) {
        if (err) {
          console.log("");
        }
      }
    },
    cancelQuestion() {
      this.open_question_modal = false;
    },
    // Funcion que ajuste el titulo del album dependiendo del ancho del contenedor del album
    handleAlbumTitle(title, width) {
      // console.log("handleAlbumTitle", title, id);
      var album_title = title !== undefined ? title : "Album";
      if (width >= "200" && album_title.length > 12) {
        return album_title.slice(0, 12) + "...";
      } else return album_title;
    },
    async eraseText() {
      if (this.search_text.length == 0) {
        await this.getPaginadasGaleriaLista();
        //this.galleries_list = this.album_list;
        this.no_results = "";
      }
    },
    // Funcion para avanzar o retroceder en modal de fotos
    plusSlides(n) {
      this.showSlides((this.slide_idx += n));
    },
    // Funcion para mostrar foto cuando se hga click en alguno de los puntos del modal de fotos
    currentSlide(n) {
      this.showSlides((this.slide_idx = n));
    },
    // funcion que muestra foto en modal
    showSlides(n) {
      let i;
      let slides = this.$refs.mySlides;
      let dots = document.getElementsByClassName("dot");

      if (n > slides.length) {
        this.slide_idx = 1;
      }
      if (n < 1) {
        this.slide_idx = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[this.slide_idx - 1].style.display = "block";
      dots[this.slide_idx - 1].className += " active";
    },
    // Funcion para descargar una imagen del album desc517
    async downloadImage(url, album) {
      console.log(album);
      //toBlob
      let bolb_content = await this.axios({
        url: url,
        method: "GET",
        contentType: "application/x-www-form-urlencoded",
        responseType: "blob", // Important
      }).then((res) => {
        let blob = res.data;
        return blob;
      });
      //toBase64 Webp
      /* const filePromises = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = async () => {
            try {
              const response = await reader.result;
              // Resolve the promise with the response value
              //console.log('response',response)
              resolve(response);
            } catch (err) {
              reject(err);
            }
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(file);
        });
      }; */
      //toBase64 Png
      // inspirado en https://arsfy.buzz/2022/02/20/Decode-webp-to-png-jpg-at-Browser-JavaScript/
      var oFileReader = new FileReader();
      oFileReader.onloadend = function (e) {
        // Create a new Image Obj
        var newImg = new Image();
        // Set crossOrigin Anonymous (That's important, otherwise it will not be read)
        newImg.crossOrigin = "Anonymous";
        newImg.onload = function () {
          // Create a new Canvas
          var canvas = document.createElement("canvas");
          // Set 2D context
          var context = canvas.getContext("2d");
          // Set crossOrigin Anonymous (That's important, otherwise it will not be read)
          canvas.crossOrigin = "anonymous";
          // Set Width/Height
          canvas.width = newImg.width;
          canvas.height = newImg.height;
          // Start
          context.drawImage(newImg, 0, 0);
          // Get png Base64
          let imgSrc = canvas.toDataURL("image/png");
          //download
          //creo el elemento para descargar
          const link = document.createElement("a");
          //le añado el contenido
          link.href = imgSrc;
          //le añado el titulo de archivo
          link.download = album.titulo_galeria;
          //incrusto en el html el link
          document.body.appendChild(link);
          //hago el click
          link.click();
          //elimino del html el link
          document.body.removeChild(link);
        };
        // Load Webp Base64
        newImg.src = e.target.result;
      };
      oFileReader.readAsDataURL(bolb_content);
      this.show_spinner = false;
    },
    // like a una imagen del album
    likeImage(imgId, albumId) {
      console.log("Acá no debe dar like, dado que es un adminsitrador: ", imgId, " ", albumId);
      // this.$apollo
      //   .mutate({
      //     mutation: LIKE_IMAGE,
      //     variables: {
      //       id_empresa: this.id_empresa,
      //       id_usuario: this.id_usuario,
      //       id_imagen: imgId,
      //       id_galeria: albumId,
      //     },
      //   })
      //   .then((res) => {
      //     var resp = res.data.darLikeImagen;
      //     // console.log("ME GUSTA resp", resp);
      //     var year = this.date.getFullYear();
      //     resp.id_empresa = this.id_empresa;
      //     resp.fecha_inicio = year + "-01-01";
      //     resp.fecha_final = this.getCurrentDate();
      //     this.editGalleryAction(resp);
      //     this.album_selected.imagenes = resp.imagenes;
      //     // console.log("likeImage album_selected", this.album_selected);
      //     setTimeout(() => {
      //       this.galleries_list = this.album_list;
      //     }, 1000);
      //   })
      //   .catch((err) => {
      //     console.log("err", err);
      //   });
    },
    // quitar like de imagen de album
    unlikeImage(imgId, albumId) {
      console.log("Acá no debe quitar like, dado que es un adminsitrador: ", imgId, " ", albumId);
      // this.$apollo
      //   .mutate({
      //     mutation: UNLIKE_IMAGEN,
      //     variables: {
      //       id_empresa: this.id_empresa,
      //       id_usuario: this.id_usuario,
      //       id_imagen: imgId,
      //       id_galeria: albumId,
      //     },
      //   })
      //   .then((res) => {
      //     var resp = res.data.quitarLikeImagen;
      //     // console.log("NO ME GUSTA resp", resp);
      //     var year = this.date.getFullYear();
      //     resp.id_empresa = this.id_empresa;
      //     resp.fecha_inicio = year + "-01-01";
      //     resp.fecha_final = this.getCurrentDate();
      //     this.editGalleryAction(resp);
      //     this.album_selected.imagenes = resp.imagenes;
      //     // console.log("unlikeImage album_selected", this.album_selected);
      //     setTimeout(() => {
      //       this.galleries_list = this.album_list;
      //     }, 1000);
      //   })
      //   .catch((err) => {
      //     console.log("err", err);
      //   });
    },
    // fecha actual
    getCurrentDate() {
      var day = this.date.getDate();
      var month = this.date.getMonth() + 1;
      var year = this.date.getFullYear();

      if (day < 10) {
        day = "0" + day.toString();
      }
      if (month < 10) {
        month = "0" + month.toString();
      }

      return year + "-" + month + "-" + day;
    },
    // funcion para mostrar/ocultar boton que da like y boton que quita like
    handlelikeBtn(img, button) {
      //lista de likes que corresponden a la img que se esta viendo en el modal
      var likes = img.likes;
      if (likes && likes.length > 0) {
        for (let i = 0; i < likes.length; i++) {
          for (let x = 0; x < likes[i].usuario.length; x++) {
            if (likes[i].usuario[x].id_usuario == this.id_usuario) {
              // Si el usuario que esta en el arr es igual al logeado
              // debe mostrar el btn 'quitar like' y esconder el otro
              return button == "like" ? false : true;
            } else {
              // si no son el mismo usuario debe mostrar boton de 'dar like'
              return button == "like" ? true : false;
            }
          }
        }
      } else {
        return button == "like" ? true : false;
      }
    },
    // funcion para 'activar' visualmente los meses que tengan albumes
    handleMonthListClass(month) {
      for (let i = 0; i < this.month_list.length; i++) {
        if (month == this.month_list[i].name) {
          return "active";
        }
      }
    },
    // funcion que retorna 'active' cuando hay likes en el album
    handleLikeIconClass(likes) {
      if (likes > 0 && likes !== null) {
        return true;
        // return "active";
      } else {
        return false;
      }
    },
    // Descargar album
    async downloadAlbum(album) {
      // ref: https://stackoverflow.com/questions/69924072/jszip-wait-for-creation-of-zip-before-working-with-data
      // inspirado en https://arsfy.buzz/2022/02/20/Decode-webp-to-png-jpg-at-Browser-JavaScript/
      this.show_spinner = true;
      let files = [];
      //extraigo un array de Blobs de google cloud 
      for (let i = 0; i < album.imagenes.length; i++) {
        // funcion busca el de blob y nombre de imagen de galeria + formato y genera un array con estos
        let bolb_content = await this.axios({
          url: album.imagenes[i].url_imagen_original,
          method: "GET",
          contentType: "application/x-www-form-urlencoded",
          responseType: "blob", // Important
        }).then((res) => {
          let blob = res.data;
          /* let format_original =
            album.imagenes[i].url_imagen_original.split(".")[
              album.imagenes[i].url_imagen_original.split(".").length - 1
            ]; */
          return {
            data: blob,
            name:
              album.titulo_galeria.replaceAll(/ /g, "_") +
              "_" +
              (i + 1) +
              ".png",
          };
        });
        files.push(bolb_content);
      }
      let filesPng = [];
      //Genera promesas de imagenes de cualquier formato parceadas a png
      const getFilesPngPromises = files.map((file) => {
        return new Promise((resolve, reject) => {
          var oFileReader = new FileReader();
          oFileReader.onloadend = function (e) {
            // Create a new Image Obj
            var newImg = new Image();
            // Set crossOrigin Anonymous (That's important, otherwise it will not be read)
            newImg.crossOrigin = "Anonymous";
            newImg.onload = function () {
              // Create a new Canvas
              var canvas = document.createElement("canvas");
              // Set 2D context
              var context = canvas.getContext("2d");
              // Set crossOrigin Anonymous (That's important, otherwise it will not be read)
              canvas.crossOrigin = "anonymous";
              // Set Width/Height
              canvas.width = newImg.width;
              canvas.height = newImg.height;
              // Start
              context.drawImage(newImg, 0, 0);
              // Get png Base64
              let imgSrc = canvas.toDataURL("image/png");
              resolve(filesPng.push(imgSrc.split(",")[1]));
            };
            newImg.onerror = (error) => {
              reject(error);
            };
            // Load Webp Base64
            newImg.src = e.target.result;
          };
          oFileReader.readAsDataURL(file.data);
        });
      });
      await Promise.all(getFilesPngPromises);
      // funcion que genera el zip final
      let uploadDocuments = async (files) => {
        //array de base64s
        const fileInfos = filesPng;
        let zip = new JSZip();
        //pushea los archivos base 64 y sus nombres al objeto zip
        for (let i = 0; i < files.length; i++) {
          zip.file(files[i].name, fileInfos[i], { base64: true });
        }
        // genera zip
        zip.generateAsync({ type: "blob" }).then(function (content) {
          // see FileSaver.js
          saveAs(content, album.titulo_galeria + ".zip");
        });
        this.show_spinner = false;
        this.$toast.open({
          message: 'Álbum descargado correctamente.',
          type: 'success',
          duration: 5000,
          position: 'top-right'
        })
        // Profit
        return fileInfos;
      };
      uploadDocuments(files);
    },

    // Descarga ALBUM 2
    async loadImagesDownload(imagen) {
      var fileName = imagen.split(/(\\|\/)/g).pop();
      var image = new Image();

      image.crossOrigin = "anonymous";
      image.src = imagen;
      let self = this;
      image.onload = function () {
        // use canvas to load image
        var canvas = document.createElement("canvas");
        // canvas.width = this.naturalWidth;
        // canvas.height = this.naturalHeight;
        canvas.getContext("2d").drawImage(this, 0, 0);

        // grab the blob url
        var blob;
        if (image.src.indexOf(".jpg") > -1) {
          blob = canvas.toDataURL("image/jpeg");
        } else if (image.src.indexOf(".png") > -1) {
          blob = canvas.toDataURL("image/png");
        } else if (image.src.indexOf(".gif") > -1) {
          blob = canvas.toDataURL("image/gif");
        } else {
          blob = canvas.toDataURL("image/png");
        }
        self.getInfoBlob(blob, fileName);
      };
      // console.log(arrayDef);
    },
    // Descargan ALBUM 3
    async getInfoBlob(blob, fileName) {
      const code = await fetch(blob);
      var data = {
        input: code.url,
        name: fileName,
      };
      this.arrayDef.push(data);
      // console.log(this.arrayDef);
      this.counterAlbum++;
      if (this.counterAlbum == this.totalLengthAlbum) {
        this.downloadDef();
      }
    },
    // Descargar ALBUM 4
    async downloadDef() {
      console.log("downloadDef", this.arrayDef);
      var arrayZip = this.arrayDef;
      // console.log(arrayZip);
      const blob = await downloadZip(arrayZip).blob();
      // console.log(blob);

      // make and click a temporary link to download the Blob
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = this.nombreGaleria;
      link.click();
      link.remove();
      this.arrayDef = [];
    },
    handleCalendar() {
      // console.log("click outside calendar");
      this.show_calendar = false;
    },

    clearDates(){
      this.galleries_list = this.album_list;
      this.no_results = "";
      this.end_date = 'Término';
      this.init_date = 'Inicio';
      this.date_start = '';
      this.date_end = '';
      this.inicio = '';
      this.fin = '';
      this.show_calendar = false;
      this.show_back_btn = false;
      this.searchAlbumByDate();
    },

    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        this.date_init =
          this.init_date != "Inicio" ? new Date(this.init_date) : "";
        this.max_date = this.end_date == "Término" ? "" : this.end_date;
        this.min_date = "";
      } else {
        var min = new Date(this.init_date);
        this.min_date = min.setDate(min.getDate() + 1);
        this.max_date = "";
        this.min_date = this.init_date == "Inicio" ? "" : this.init_date;
      }
    },
    onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.init_date = day.id;
      } else {
        this.end_date = day.id;
      }
      if (this.init_date != "Inicio" && this.end_date != "Término") {
        this.searchAlbumByDate();
      }
      this.show_calendar = false;
    },

    toastDeleteGallerySuccess() {
      this.$toast.open({
        message: 'Álbum eliminado correctamente.',
        type: 'success',
        duration: 5000,
        position: 'top-right'
      });
    },
    toastDeleteGalleryError() {
      this.$toast.open({
        message: 'Ocurrió un error al eliminar el álbum. Por favor inténtalo nuevamente.',
        type: 'error',
        duration: 8000,
        position: 'top-right'
      });
    },
    toastGetGalleriesError() {
      this.$toast.open({
        message: 'Ocurrió un error al intentar obtener las galerías. Por favor inténtalo nuevamente.',
        type: 'error',
        duration: 8000,
        position: 'top-right'
      });
    }
  },
};
</script>
