<template>
   <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="48.739"
      height="43.517"
      viewBox="0 0 48.739 43.517"
   >
      <defs>
         <clipPath id="clip-path">
            <rect
               id="Rectángulo_4863"
               data-name="Rectángulo 4863"
               width="48.739"
               height="43.517"
               transform="translate(0 0)"
               fill="none"
            />
         </clipPath>
      </defs>
      <g id="Grupo_14986" data-name="Grupo 14986" transform="translate(0 0)">
         <g
            id="Grupo_14987"
            data-name="Grupo 14987"
            clip-path="url(#clip-path)"
         >
            <path
               id="Trazado_9482"
               data-name="Trazado 9482"
               d="M24.3,43.517a4.186,4.186,0,0,1-2.792-1.067C17.349,38.735,7.311,29.577,4,24.781c-2.107-3.053-4-6.118-4-10.05A14.744,14.744,0,0,1,14.728,0a14.567,14.567,0,0,1,9.643,3.609A14.536,14.536,0,0,1,34.606.014,14.852,14.852,0,0,1,48.637,12.988a14.7,14.7,0,0,1-2.75,10.43c-3.655,5.126-14.344,15-18.771,19.008A4.181,4.181,0,0,1,24.3,43.517"
               fill="#ec206b"
            />
         </g>
      </g>
   </svg>
</template>

<script>
export default {};
</script>
