<template>
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48.739"
      height="43.517"
      viewBox="0 0 48.739 43.517"
   >
      <path
         id="Trazado_9483"
         data-name="Trazado 9483"
         d="M24.3,43.517a4.186,4.186,0,0,1-2.792-1.067C17.349,38.735,7.311,29.577,4,24.781c-2.107-3.053-4-6.118-4-10.05A14.744,14.744,0,0,1,14.728,0a14.567,14.567,0,0,1,9.643,3.609A14.536,14.536,0,0,1,34.606.014,14.852,14.852,0,0,1,48.637,12.988a14.7,14.7,0,0,1-2.75,10.43c-3.655,5.126-14.344,15-18.771,19.008A4.181,4.181,0,0,1,24.3,43.517M14.731,2.427a12.319,12.319,0,0,0-12.306,12.3c0,3.234,1.688,5.949,3.569,8.674,3.162,4.581,13.033,13.581,17.13,17.238a1.769,1.769,0,0,0,2.369-.01C29.861,36.675,40.4,26.944,43.918,22.011a12.29,12.29,0,0,0-9.4-19.578,12.145,12.145,0,0,0-9.274,3.689l-.866.885-.865-.885a12.182,12.182,0,0,0-8.783-3.7Z"
         transform="translate(0 0)"
         fill="#0072f7"
      />
   </svg>
</template>

<script>
export default {};
</script>